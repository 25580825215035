import React from "react";
import { Link } from "react-router-dom";
import "./components.css";

type Props = {};

const Navbar = (props: Props) => {
  return (
    <section className="navbar">
      <Link className="logo" to="/">
        Clearo
      </Link>
      <Link className="privacy" to="privacy">
        Privacy Policy
      </Link>
    </section>
  );
};

export default Navbar;
