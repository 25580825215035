import React from "react";
import Footer from "../components/Footer";
import "../App.css";

type Props = {};

const Home = (props: Props) => {
  return (
    <div>
      <img
        className="icon"
        src="./icon.png"
        alt="Clearo"
        width="500"
        height="500"
      />
    </div>
  );
};

export default Home;
